import React , {useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import LogoComponent from './LogoComponent'
import CopyRight from './CopyRight'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DEFAULTS } from '../../Constants/Constants'

const theme = createTheme();



export default function ConfirmEmail() {

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  console.log("state", data.from)

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://ikeocean.com/wp-content/uploads/2020/05/containers-1.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
                    
           {(data.from == "SIGNUP" ) && <><Typography component="h1" variant="h5">Verify Your Email Address</Typography>
           <Typography variant="body1"><br/><br/>We have sent your a verification link to complete the registration.</Typography>
           <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={()=>{navigate('/auth/login')}}
              >
                Login
              </Button>
              </>}

              {(data.from == "AGENT-SIGNUP" ) && <><Typography component="h1" variant="h5">Thank You</Typography>
           <Typography variant="body1">
           <br/><br/>
           Welcome to ShipHub IKEOCEAN! We are thrilled that you’re considering joining our dynamic network of freight and logistics professionals. Together, we’re shaping the future of global logistics and creating generational wealth for everyone involved. Your expertise could play a key role in this impactful journey.
           <br/><br/>
To help us better understand your background and aspirations, Please take a few moments to complete the <a href="https://ikeocean.com/careers/" target="_blank">Pre-Questionnaire Here</a>. This step will allow us to learn more about you.
<br/><br/>
Thank you for your interest in ShipHub IKEOCEAN. We can’t wait to learn more about you and explore how we can drive success together!
           <br/><br/>
</Typography>
           
              </>}

             
          </Box>
          
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}