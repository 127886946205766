import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import Quotes from './Quotes';
import axios from 'axios';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import WalletIcon from '@mui/icons-material/Wallet';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Title from './Title';
import { RootState, useAppDispatch } from '../../../../store'
import { useSelector } from 'react-redux'
import { decrement, increment } from '../../../../counterSlice'

import { updateProfile,
  selectProfile, fetchProfile} from '../../../../features/profile/profileSlice'

  type customerProfileType = {
    [key: string]: any;
  }
  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

function Dashboard() {



  const CUSTOMERPROFILE : customerProfileType = useSelector((state: RootState) => state.profile);
  const dispatch = useAppDispatch();
 // let CUSTOMERPROFILE : customerProfileType = useAppSelector(fetchProfile)
 useEffect(() => {
  dispatch(fetchProfile());
setTimeout(() => {

}, 5000); 
}, [dispatch]);

  console.log("CUSTOMERPROFILE", CUSTOMERPROFILE?.value, CUSTOMERPROFILE?.value?.firstname)

    return (
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
              <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 200,
        borderRadius: '15px', // Rounded corners
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for depth
        backgroundColor: '#fff', // Card background color
        color: '#333', // Text color
        overflow: 'hidden', // Prevents content overflow
      }}
    >
      <React.Fragment>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <WalletIcon style={{ color: '#ED1C24', fontSize: '40px' }} />
          <Typography style={{ marginLeft: '8px', fontSize: '1.5em' }}>
            Available Wallet Balance
          </Typography>
        </div>
        <Typography component="p" variant="h3" style={{ marginBottom: '20px' }}>
          ${Number(CUSTOMERPROFILE?.value?.wallet).toFixed(2)}
        </Typography>
        <div>
          <Link color="primary" href="/portal/wallet">
            View balance
          </Link>
        </div>
      </React.Fragment>
    </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={8}>
                
              
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', mb: 2 }}>
                  <Orders />
                </Paper>

                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Quotes />
                </Paper>
              </Grid>
            </Grid>
            {/* <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <span>{count}</span>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(decrement())}
        >
          Decrement
        </button> */}
        <span></span>
        {/* <button
          aria-label="Decrement value"
          onClick={() => dispatch(change({name:'kumar'}))}
        >
          update shipment
        </button> */}
          </Container>
    );
  }

  

  export default Dashboard;