import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import pic from '../assets/images/ikeocean-logo_transparent_white-300x57.png';

import { useSelector } from 'react-redux'

interface Props {
  window: Window;
}
const drawerWidth = 240;
// Destructure 'window' from 'Props'

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#070808',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#070808',
    },
  },
});

function Header(props: Props) {
    const navigate = useNavigate();
    const { window } = props;
    
    const [mobileOpen, setMobileOpen] = React.useState(false);
  
    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        {/* <Typography variant="h6" sx={{ my: 2 }}>
          MUI
        </Typography>
        <Divider /> */}
        <List>
          
        <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/auth/agent'>Agents</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/auth/tracking'>Tracking</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/auth/login'>Sign In</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/auth/signup'>Sign Up</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='https://ikeocean.com'>ikeocean.com</Link>
              </ListItemButton>
            </ListItem>
        </List>
      </Box>
    );
    const container = window !== undefined ? window.document.body : undefined;

    return (
        <Box sx={{ flexGrow: 1 }}>
          
      <AppBar position="static" style={{background:'#070808',zIndex:100}}>
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
           <a href="/auth/home"><img src={pic} /></a>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Stack spacing={2} direction="row">
      {/* <Button variant="text" style={{color:'#f7fcfc'}} onClick={()=>navigate('/auth/home/quote')}>LOCAL COURIER</Button>*/}
      {/* <Button  style={{color:'#f7fcfc'}}  onClick={(event)=>{
        event.preventDefault()
        navigate('/auth/quote')}}>GET QUOTE</Button>  */}
        <Button  style={{color:'#f7fcfc'}}  onClick={()=>navigate('/auth/agent')}>AGENTS</Button>
      <Button  style={{color:'#f7fcfc'}}  onClick={()=>navigate('/auth/tracking')}>TRACKING</Button>
      <Button variant="text" style={{color:'#f7fcfc'}}   onClick={()=>navigate('/auth/login')}>Sign In</Button>
      <Button variant="contained" style={{backgroundColor:'#eb4034'}} onClick={()=>navigate('/auth/signup')}>Sign Up</Button>
    </Stack></Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
    );
  }

  

  export default Header;