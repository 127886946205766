import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField, Button, InputLabel, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULTS } from '../../../../Constants/Constants'
type MyState = Array<any> | Record<string, any>;
const convertFirestoreTimestampToDate = (timestamp: any): string => {
  const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6); // Convert Firestore Timestamp to JavaScript Date
  return date.toLocaleString(); // Convert Date to a readable date and time format
};

const pre = (ship_type: any) => {
  switch (ship_type) {
    case 'air':
      return "A1";
    case 'ocean':
      return "S1";
    default:
      return "T1";
  }
};
function Quote() {
    const [shipQuotes, setShipQuotes] = useState([]);
    const [shipErrors, setShipError] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [dataFetched, setDataFetched] = useState(true)
    const token = localStorage.getItem('user-token');
    

    const columns = [
      {
        field: "quoteId",
        headerName: "Quote #",
        width: 100,
        renderCell: (params: any) => (

       <a href={`/portal/quote/view/${params.row.quoteId}`}>{pre(params.row.ship_type) + params.row.quoteId}</a>
        )
      },
      {
        field: "quoteDate",
        headerName: "Date",
        width: 200,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          convertFirestoreTimestampToDate(params.row.quoteDate)
        )
      },
      {
        field: "quoteType",
        headerName: "Type",
        width: 100,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          params.row.quoteType.toUpperCase()
        )
      },
      {
        field: "quoteName",
        headerName: "Name",
        width: 200,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          params.row.quoteName.toUpperCase()
        )
      },
      {
        field: "quoteEmail",
        headerName: "Email",
        width: 200,
        sortable: true,
        filter:false
      },
      {
        field: "quoteDetails.ship_date",
        headerName: "Ship Date",
        width: 200,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          params.row.quoteDetails.ship_date
        )
      },
      // {
      //   field: "quoteMobile",
      //   headerName: "Mobile",
      //   width: 200,
      //   sortable: true,
      //   filter:false
      // },
     
      {
        field: "quoteStatus",
        headerName: "Status",
        width: 100,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          params.row.quoteStatus.toUpperCase()
        )
      },
      // {
      //   field: "quoteAssigned",
      //   headerName: "Assigned To",
      //   width: 200,
      //   sortable: true,
      //   filter:false
      // }
    ];
  



      const getQuotes = async () => {
        setLoading(true)
        await axios.get(DEFAULTS.API_URL+'v1/customer/quotes',{headers: {
          'Authorization': `Bearer ${token}`
        }})
        
           .then(
             response => {
              const rowsWithIds = response.data.map((row: any, index: any) => ({
                ...row,
                id: index + 1, // You can use any unique identifier here
              }));
              setShipQuotes(rowsWithIds.sort((a: any, b: any) => b.quoteId - a.quoteId));
              setLoading(false);
                            
             }
              // response.data.rate_response.rates
             
           )
       }
       
       useEffect(() => {
         // Update the document title using the browser API
         //expensesListResp();
         getQuotes();
       },[]);

    return (
      <div>
        
        <Container>
        <Typography variant="h5">Quotes</Typography>
            <Card variant="outlined">
                <CardContent>
                <DataGrid
        rows={shipQuotes}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.quoteId}
        loading={loading}       
        style={{marginBottom:10}}
      />

</CardContent>
</Card>
      </Container>

      </div>
    );
  }


  
  export default Quote;